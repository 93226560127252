<template>
    <div class="grid">
        <div class="col-12 md:col-12">
            <div class="card p-fluid">
                <h5>{{ subArea.id ? 'Atualizar Sub Área' : 'Nova Sub Área' }}</h5>
                <div class="field col-9">
                    <label class="required">Título</label>
                    <InputText type="text" v-model="subArea.title" />
                </div>
                <div class="field col-12 md:col-3">
                    <label class="required">Área</label>
                    <Dropdown v-model="subArea.area.id" :options="areas" optionLabel="title" optionValue="id" />
                </div>
                <div class="field col-9">
                    <label class="required">Sub Temática Sas</label>
                    <Dropdown v-model="subArea.codigoSas" :options="subTema" optionValue="CodAreaTematica" optionLabel="DescAreaTematica" showClear />
                </div>
                <div class="field-checkbox col-10">
                    <Checkbox id="binary" v-model="subArea.active" :binary="true" />
                    <label for="binary">Ativo</label>
                </div>
            </div>
            <Button label="Cancelar" @click.stop="cancelar" icon="pi pi-times-circle" class="mr-2 p-button-secondary"></Button>
            <Button
                label="Salvar"
                @click.stop="inserir"
                icon="pi pi-check-circle"
                :disabled="!subArea.title || !subArea.area.id || !subArea.codigoSas"
            ></Button>
        </div>
    </div>
</template>

<script>
import Services from './service';
import AreaService from '../area/service';

export default {
    data() {
        return {
            subArea: {
                title: null,
                area: {
                    id: null,
                    codigoSas: null,
                },
                active: true,
                codigoSas: null,
                descricaoSas: null,
                temaSuperiorID: null,
            },
            areas: null,
            subTema: [],
            loading: false,
        };
    },
    mounted() {
        if (this.$route.params.id) {
            this.loading = true;
            Services.obterPorId(this.$route.params.id).then((response) => {
                if (response?.success) {
                    this.subArea = response.data;
                    if (this.subArea.codigoSas != null) {
                        this.obterSubTematicaSas(this.subArea.area.codigoSas);
                    }
                }
            });
        }
        this.obterAreas();
    },
    methods: {
        inserir() {
            if (this.subArea.id) {
                Services.atualizar(this.subArea.id, this.subArea).then((response) => {
                    this.respostaSalvar(response, true);
                });
            } else {
                this.$store.dispatch('addRequest');
                Services.inserir(this.subArea).then((response) => {
                    this.respostaSalvar(response, false);
                });
            }
        },
        respostaSalvar(response, edicao) {
            if (response?.success) {
                this.$toast.add({
                    severity: 'success',
                    summary: 'Sub Área',
                    detail: `Sub Área ${edicao ? 'editada' : 'inserida'} com sucesso`,
                    life: 3000,
                });

                this.cancelar();
            } else {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Erro',
                    detail: response.errors[0],
                    life: 10000,
                });
            }
            this.$store.dispatch('removeRequest');
        },
        obterAreas() {
            this.$store.dispatch('addRequest');
            AreaService.obterTodos().then((response) => {
                if (response?.success) {
                    this.areas = response.data;
                }
                this.$store.dispatch('removeRequest');
            });
        },
        obterSubTematicaSas(id) {
            this.$store.dispatch('addRequest');
            Services.obterSubTematicaSas(id).then((response) => {
                if (response?.success) {
                    this.subTema = response.data;
                } else {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Erro',
                        detail: response.errors[0],
                        life: 10000,
                    });
                    let subtematicaDto = {
                        CodAreaTematica: this.subArea.codigoSas,
                        DescAreaTematica: this.subArea.descricaoSas,
                        TemaSuperiorID: this.subArea.temaSuperiorID,
                    };
                    this.subTema.push(subtematicaDto);
                }
                this.$store.dispatch('removeRequest');
            });
        },
        setarArea() {
            if (!this.loading) {
                this.subArea.codigoSas = null;
                this.subArea.temaSuperiorID = null;
                this.subArea.descricaoSas = null;
            } else {
                this.loading = false;
            }
            if (this.areas && this.subArea.area.id > 0) {
                const result = this.areas.filter((area) => area.id == this.subArea.area.id);
                this.subArea.area.codigoSas = result[0].codigoSas;
                if (this.subArea.area.codigoSas != null) this.obterSubTematicaSas(this.subArea.area.codigoSas);
            }
        },
        setarSub() {
            if (this.subArea.codigoSas > 0 && this.subTema.length > 0) {
                const result = this.subTema.filter((subTematica) => subTematica.CodAreaTematica == this.subArea.codigoSas);
                this.subArea.descricaoSas = result[0].DescAreaTematica;
                this.subArea.codigoSas = result[0].CodAreaTematica;
                this.subArea.temaSuperiorID = result[0].TemaSuperiorID;
            }
        },
        cancelar() {
            this.$router.push({
                name: `SubArea`,
            });
        },
    },
    watch: {
        'subArea.area.id'() {
            this.setarArea();
        },
        'subArea.codigoSas'() {
            this.setarSub();
        },
    },
};
</script>
